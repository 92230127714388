"use client";

import Logo from "@/components/shared/Logo";
import ModeSwitcher from "@/components/shared/ModeSwitcher";
import { ChevronRight, Menu, X } from "lucide-react";
import Link from "next/link";
import { Drawer } from "vaul";
import { navbarData } from "./Navbar";
import { RefObject, useState } from "react";
import { usePathname } from "next/navigation";
import { scrollHandler } from "./Navbar";
import { DrawerTitle } from "@/components/ui/drawer";

const MobileNavbar = () => {
  const [open, setOpen] = useState(false);
  const currentPath = usePathname();
  return (
    <Drawer.Root
      preventScrollRestoration={false}
      dismissible={false}
      direction="top"
      open={open}
    >
      <Drawer.Trigger asChild onClick={() => setOpen(true)}>
        <Menu className="w-6 h-6 transition-colors cursor-pointer hover:text-accent" />
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/40 z-50" />
        {/*  required for accessability */}
        <DrawerTitle className="hidden">Menu</DrawerTitle>
        <Drawer.Content
          className={`bg-background flex flex-col h-full w-full mt-24 fixed bottom-0 right-0 z-50`}
        >
          <div className="p-6 bg-background w-full h-full">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <Logo
                  className={`w-48 motion-delay-200 ${
                    open ? "motion-preset-blur-up-lg" : ""
                  } `}
                />
                <Drawer.Close onClick={() => setOpen(false)}>
                  <X className="w-6 h-6 hover:text-accent hover:w-8 hover:h-8 transition-all hover:cursor-pointer" />
                </Drawer.Close>
              </div>
              <div className="h-full">
                <nav className="w-full h-full flex justify-center items-center">
                  <ul className="flex flex-col w-full gap-4">
                    {navbarData.map((item, index) => (
                      <Drawer.Close key={item.id}>
                        <li
                          className={`relative overflow-hidden w-full flex justify-between items-center border p-4 rounded-lg group transition-all motion-duration-1000  motion-delay-[${
                            300 + index * 50
                          }ms] motion-preset-blur-up-lg`}
                        >
                          <Link
                            scroll={true}
                            href={``}
                            onClick={() => {
                              setOpen(false);
                              scrollHandler(item.path);
                            }}
                            className={`w-full  text-2xl font-light text-left inline-flex z-10 group-hover:text-background group-hover:font-medium transition-all duration-200
                          ${
                            item.path === currentPath
                              ? "text-background font-medium"
                              : ""
                          }`}
                          >
                            {item.label}
                          </Link>
                          <ChevronRight
                            className={`w-8 h-8 group-hover:text-background transition-all duration-200 z-10 ${
                              item.path === currentPath ? "text-background" : ""
                            } `}
                          />
                          <span
                            className={` absolute w-full h-full bg-accent transition-all duration-200  group-hover:left-0 ${
                              item.path === currentPath
                                ? "left-0"
                                : "-left-[100%]"
                            }`}
                          ></span>
                        </li>
                      </Drawer.Close>
                    ))}
                  </ul>
                </nav>
              </div>
              <div
                className="h-24 flex justify-end items-center
              "
              >
                <ModeSwitcher
                  className={` motion-delay-200 ${
                    open ? "motion-preset-blur-up-lg" : ""
                  } `}
                />
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
export default MobileNavbar;
