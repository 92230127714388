import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import Link from "next/link";

interface NavItemInterface {
  title: string;
  link: string;
  icon?: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
}

const NavItem = ({
  title,
  link,
  icon,
  isActive,
  onClick,
}: NavItemInterface) => {
  return (
    <NavigationMenuItem>
      <Link href={link} legacyBehavior passHref>
        <NavigationMenuLink
          onClick={onClick}
          className={`${navigationMenuTriggerStyle()} bg-transparent hover:bg-transparent hover:!text-accent focus:bg-transparent focus:!text-accent ${
            isActive && "text-accent"
          }`}
        >
          {icon && icon}
          {icon ? <span className={`ml-2`}>{title}</span> : title}
        </NavigationMenuLink>
      </Link>
    </NavigationMenuItem>
  );
};
export default NavItem;
