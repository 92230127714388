"use client";

import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { Button } from "@/components/ui/button";
import { RefObject } from "react";

const ModeSwitcher = ({
  className = "",
  ref,
}: {
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
}) => {
  const { setTheme, theme } = useTheme();

  return (
    <>
      <Button
        variant="outline"
        onClick={() => (theme == "dark" ? setTheme("light") : setTheme("dark"))}
        className={`${className}`}
        size={"icon"}
        ref={ref as RefObject<HTMLButtonElement>}
      >
        <Sun className="h-4 w-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
        <Moon className="absolute h-4 w-4 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
      </Button>
    </>
  );
};

export default ModeSwitcher;
