"use client";
import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import ModeSwitcher from "@/components/shared/ModeSwitcher";
import { useAccount } from "wagmi";
import NavItem from "./NavItem";
import Logo from "@/components/shared/Logo";
import MobileNavbar from "./MobileNavbar";
import Link from "next/link";

export type navbarDataType = {
  label: string;
  path: string;
  id: number;
};

export const navbarData: navbarDataType[] = [
  {
    label: "Home",
    path: "",
    id: 1,
  },
  {
    label: "Ecosystem",
    path: "ecosystem",
    id: 2,
  },
  {
    label: "RoadMap",
    path: "roadMap",
    id: 3,
  },
  {
    label: "Tokenomic",
    path: "tokenomic",
    id: 4,
  },
  {
    label: "Community",
    path: "community",
    id: 5,
  },
];
export const scrollHandler = (elementId: string, timeOut = 400) => {
  if (document !== undefined && window !== undefined) {
    const element = document.getElementById(elementId);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, timeOut);
    }
  }
};

const Navbar = () => {
  return (
    <div className="w-full py-6 flex flex-row justify-between items-center website-container">
      <Link href={"/"}>
        <Logo className=" w-36 md:w-48 " />
      </Link>
      <NavigationMenu className={`hidden lg:flex gap-6`}>
        <NavigationMenuList className="gap-4">
          {navbarData.map((item) => (
            <NavItem
              key={item.id}
              title={item.label}
              link={``}
              isActive={false}
              onClick={() =>
                item.path ? scrollHandler(item.path, 100) : false
              }
            />
          ))}
        </NavigationMenuList>
        <ModeSwitcher />
      </NavigationMenu>
      <div className="flex lg:hidden">
        <MobileNavbar />
      </div>
    </div>
  );
};
export default Navbar;
